<script lang="ts" setup>
const { isLogged, isAdmin, isExchangeOfficeOwner } = storeToRefs(useUser())
</script>

<template>
  <div w-full bg-neutral-900 class="light">
    <div
      fubex-container flex items-center w-full text-neutral-50 px-16px
      flex-col
      sm="flex-row flex-nowrap  items-start justify-between" my="40px sm:54px"
    >
      <div
        w-full
        sm="basis-520px gap-8px"
      >
        <FooterLogo />
        <div
          mt-40px sm:mt-12px text-neutral-400
        >
          <FooterCompany />
        </div>
      </div>
      <div
        flex flex-col gap-40px
        sm="flex-row justify-between grow-1"
        lg="justify-end gap-80px"
        mt-40px sm:mt-16px
      >
        <div
          v-if="isLogged" flex flex-col items-center sm:items-start gap-16px
        >
          <h3 heading6 font-700>
            Můj účet
          </h3>
          <NuxtLink to="/profile" footer-link>
            Nastavení
          </NuxtLink>
          <NuxtLink to="/profile/exchange" footer-link>
            Směna
          </NuxtLink>
          <NuxtLink to="/profile/history" footer-link>
            Historie směn
          </NuxtLink>
          <NuxtLink to="/logout" footer-link>
            Odhlásit se
          </NuxtLink>
          <NuxtLink v-if="isExchangeOfficeOwner" to="/profile/exchangeoffice" footer-link>
            Moje směnárna
          </NuxtLink>
          <NuxtLink v-if="isAdmin" to="/profile/admin/bank-accounts" footer-link>
            Administrace
          </NuxtLink>
        </div>
        <div flex flex-col items-center sm:items-start gap-16px paragraph-medium>
          <h3 heading6 font-700>
            Můj účet
          </h3>
          <WidgetAuthDialogs default-view="login">
            <h4 footer-link>
              Přihlásit se
            </h4>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="register">
            <h4 footer-link>
              Registrace
            </h4>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="forgottenPassword">
            <h4 footer-link>
              Zapomenuté heslo
            </h4>
          </WidgetAuthDialogs>
        </div>
      </div>
    </div>
  </div>
</template>
